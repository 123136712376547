<template>
  <modal name="message-from-api" @closed="closeMessageFromAPI" :scrollable="true" :width="800" :height="500"
         :maxHeight="500">

    <div class="container-fluid">

      <div class="row">
        <div class="col-8"><h4 class="pl-0">Innan du kan avsluta ärendet..</h4></div>
        <div class="col">
          <button type="submit" class="btn btn-success float-right mt-3 mr-3" @click="closeMessageFromAPI">OK</button>
        </div>
      </div>
    </div>


    <div class="container-fluid">
      <div class="row bb-1 pb-3 bt-1 pt-3 bg-danger text-white fw-500">
        <div class="col"> Välj rätt alternativ för de fält som kräver åtgärd och tryck sedan på Avsluta igen.</div>
      </div>
    </div>
    <div class="mt-2">

      <div class="container-fluid">
        <div class="row">
          <div class="col-12 bb-1 pt-3 pb-3" v-for="message in messages" :key="message.label">

            <div class="single-message">
              <div v-if="message.value === null">
                Fältet <strong>{{ message.label }}</strong> behöver ses över för att ärendet ska kunna avslutas.
              </div>

              <div v-if="message.value !== null">
                Fältet <strong>{{ message.label }}</strong> behöver vara ifyllt med något annat än
                <strong>{{ message.value }}</strong> för att ärendet ska kunna avslutas.

              </div>
            </div>

          </div>
        </div>

      </div>


    </div>
  </modal>
</template>
<script>
export default {
  data() {
    return {
      getSortByOrder: true,
      getSortBy: '_score',
    };
  },
  props: {
    messages: Array
  },
  mounted() {
    this.$modal.show('message-from-api');
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {

    closeMessageFromAPI() {
      this.$emit('closed');
    }
  },
};
</script>
