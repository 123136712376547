<template>
  <modal name="select-local-office-modal" @closed="closeSelectNewLocalOffice" :scrollable="true" :width="1200"
         :height="670" :maxHeight="600">
    <h4>{{ $t('labels.choose-a-new') }} {{ $t('labels.local-office') }}</h4>

    <div class="mt-2">
      <reactive-base
          app="ticket_local_office"
          :credentials="user.user.es_username + ':' + user.user.es_password"
          :url="elasticUrl"
      >
        <div class="widget widget-modal">
          <div class="widget-header">
            <div class="widget-header-toolbar">
              <div class="actions">
                <div class="filter-action ml-3">
                  <multi-dropdown-list
                      :placeholder="this.$t('labels.police-region')"
                      componentId="policeFilter"
                      :URLParams="true"
                      fuzziness="AUTO"
                      :showSearch="true"
                      sortBy="asc"
                      nestedField="police_region"
                      :react="{ and: ['search', 'postalCode', 'countyFilter', 'municipalityFilter', 'officeFilter'] }"
                      :filterLabel="this.$t('labels.police-region')"
                      dataField="police_region.label.raw"
                  ></multi-dropdown-list>
                </div>
                <div class="filter-action ml-3">
                  <multi-dropdown-list
                      :placeholder="this.$t('labels.county')"
                      componentId="countyFilter"
                      :URLParams="true"
                      fuzziness="AUTO"
                      :size="100"
                      :showSearch="true"
                      sortBy="asc"
                      nestedField="county"
                      :react="{ and: ['search', 'postalCode', 'policeFilter', 'municipalityFilter', 'officeFilter'] }"
                      :filterLabel="this.$t('labels.county')"
                      dataField="county.label.raw"
                  ></multi-dropdown-list>
                </div>
                <div class="filter-action ml-3">
                  <multi-dropdown-list
                      :placeholder="this.$t('labels.municipality')"
                      componentId="municipalityFilter"
                      :URLParams="true"
                      fuzziness="AUTO"
                      sortBy="asc"
                      :showSearch="true"
                      :size="1000"
                      nestedField="municipality"
                      :react="{ and: ['search', 'postalCode', 'policeFilter', 'countyFilter', 'officeFilter'] }"
                      :filterLabel="this.$t('labels.municipality')"
                      dataField="municipality.label.raw"
                  ></multi-dropdown-list>
                </div>
                <div class="filter-action ml-3">
                  <multi-dropdown-list
                      :placeholder="this.$t('labels.victim-support-centre')"
                      componentId="officeFilter"
                      :URLParams="true"
                      sortBy="asc"
                      fuzziness="AUTO"
                      :showSearch="true"
                      :size="100"
                      :react="{ and: ['search', 'postalCode', 'policeFilter', 'countyFilter', 'municipalityFilter'] }"
                      :filterLabel="this.$t('labels.victim-support-centres')"
                      dataField="office.raw"
                  ></multi-dropdown-list>
                </div>
                <div class="search-action ml-3">
                  <data-search
                      componentId="postalCode"
                      dataField="postal_codes.postal_code.raw"
                      nestedField="postal_codes"
                      iconPosition="right"
                      className="data-search"
                      :showClear="true"
                      :URLParams="true"
                      :fuzziness="0"
                      :debounce="100"
                      :placeholder="this.$t('labels.postal-code') + '..'"
                      :filterLabel="this.$t('labels.search')"
                      :autosuggest="false"
                  />
                </div>
                <div class="search-action ml-3">
                  <data-search
                      componentId="search"
                      :dataField="[
											'label',
											'label_raw',
											'other_places_indexed',
											'post_towns_indexed',
											'exposed_areas_indexed',
											'office',
											'municipality_indexed',
											'police_region_indexed',
											'county_list',
                      'municipality_list'
										]"
                      iconPosition="right"
                      className="data-search"
                      :showClear="true"
                      :highlight="true"
                      :URLParams="true"
                      :fuzziness="2"
                      :placeholder="this.$t('labels.search') + '..'"
                      :debounce="100"
                      :filterLabel="this.$t('labels.search')"
                      :autosuggest="false"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="widget-body no-padding">
            <selected-filters class="bb-1 curent-filters pl-4" :clearAllLabel="this.$t('labels.clear')"/>
          </div>
          <div class="widget-body no-padding">
            <reactive-list
                :showResultStats="false"
                componentId="SearchResult"
                className="result-list-container"
                :pagination="true"
                :dataField="getSortBy"
                :sortBy="getSortByOrder ? 'desc' : 'asc'"
                :URLParams="true"
                :from="0"
                :size="15"
                :react="{ and: ['search', 'postalCode', 'policeFilter', 'municipalityFilter', 'officeFilter'] }"
            >
              <table class="w-100 vuetable" slot="render" slot-scope="{ loading, error, data }">
                <thead>
                <tr>
                  <th
                      width="20%"
                      :class="{ 'sort-up': isSortField('office.raw', true), 'sort-down': isSortField('office.raw', false) }"
                      @click="changeSortBy('office.raw')"
                  >
                    {{ $t('labels.victim-support-centre') }}
                  </th>
                  <th
                      width="20%"
                      :class="{ 'sort-up': isSortField('label.raw', true), 'sort-down': isSortField('label.raw', false) }"
                      @click="changeSortBy('label.raw')"
                  >
                    {{ $t('labels.local-office') }}
                  </th>
                  <th
                      width="20%"
                      :class="{
												'sort-up': isSortField('municipality_list.raw', true),
												'sort-down': isSortField('municipality_list.raw', false),
											}"
                      @click="changeSortBy('municipality_list.raw')"
                  >
                    {{ $t('labels.municipality') }}
                  </th>
                  <th
                      width="16%"
                      :class="{
                        'sort-up': isSortField('police_region_list.raw', true),
                        'sort-down': isSortField('police_region_list.raw', false),
                      }"
                      @click="changeSortBy('police_region_list.raw')"
                  >
                    {{ $t('labels.place') }}
                  </th>
                  <th width="10%"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in data" :key="item.id">
                  <td width="20%" v-html="item.office"></td>
                  <td width="20%" v-html="item.label"></td>
                  <td width="20%" v-html="item.municipality_list"></td>
                  <td width="16%" style="font-size: 11px;" v-html="item.post_towns_indexed.replace(/,/g, ', ')"></td>
                  <td width="10%" class="text-right pr-4">
                    <button class="btn btn-success" @click="selectLocalOffice(item.id, item.label)">{{ $t('labels.select') }}</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </reactive-list>
          </div>
        </div>
      </reactive-base>
    </div>
  </modal>
</template>
<script>
export default {
  data() {
    return {
      elasticUrl: process.env.VUE_APP_API_ELASTIC,
      getSortByOrder: true,
      getSortBy: '_score',
    };
  },
  mounted() {
    this.$modal.show('select-local-office-modal');
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    closeSelectNewLocalOffice() {
      this.$emit('closed');
    },
    isSortField(field, direction) {
      if (this.getSortBy === field && this.getSortByOrder === direction) {
        return true;
      } else {
        return false;
      }
    },

    selectLocalOffice(local_office_id, local_office_label) {
      let regex = /(<([^>]+)>)/ig;
      local_office_label = local_office_label.replace(regex, "");

      this.$modal.hide('select-local-office-modal');
      this.$emit('closed', local_office_id, local_office_label);
    },
  },
};
</script>
