<template>
  <div>
    <div class="row">
      <div class="col-4">
        <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">
          {{ label_boj }}<info-box :field="infoBox_boj" />
          <button
              @click="handleClick"
              v-if="crime.primary === 0 && editable && ticket.is_anonymized !== 1"
              class="btn btn-outline-danger float-right btn-sm"
              style="margin-top: -6px;"
          >
            <i class="fas fa-trash"></i>
          </button>
        </h5>
        <select-field
            :clearable="true"
            :model="crime"
            :multiple="false"
            :options="options.crime"
            db_field="crime_id"
            field="crime"
            :editable="ticket.is_anonymized !== 1 && editable"
            api="updateCrimeField"
            :fullWidth="true"
            @fieldSaved="updatePoliceCrimeFields"
            :optionLabel="this.$label()"
            label=""
        ></select-field>
        <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">{{ label_police }}<info-box :field="infoBox_police_primary" /></h5>
        <text-field
            v-if="!ticket.is_anonymized"
            :editable="ticket.is_anonymized !== 1 && editable"
            api="updateCrimeField"
            :model="crime"
            field="crime_scene"
            :label="this.$t('labels.crime-scene')"
            type="text"
        ></text-field>
        <text-field
            :editable="ticket.is_anonymized !== 1 && editable"
            api="updateCrimeField"
            :model="crime"
            field="crime_happened_at"
            :label="this.$t('labels.crime-occurred')"
            type="text"
        ></text-field>
        <text-field
            :editable="ticket.is_anonymized !== 1 && editable"
            api="updateCrimeField"
            :model="crime"
            :save="false"
            @changed="searchPoliceCrimeCode"
            field="police_crime_type"
            :label="this.$t('labels.criminal-code')"
            type="text"
        >
        </text-field>
      </div>
      <div class="col-8 bl-1">
        <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ label_police }}<info-box :field="infoBox_police_secondary" /></h5>
        <select-field-police-crimes
            :clearable="false"
            :model="crime"
            :multiple="false"
            :options="policeCrimesById"
            db_field="police_crime_id"
            field="police_crime"
            :editable="ticket.is_anonymized !== 1 && editable"
            api="updateCrimeField"
            :fullWidth="true"
            @fieldSaved="setPoliceCrimeCode"
            :optionLabel="this.$label()"
            label=""
        ></select-field-police-crimes>

      </div>
    </div>
  </div>
</template>
<script>
import BojAPI from '@/api/boj';
import TextField from './fields/TextField';
import SelectField from './fields/SelectField';
import SelectFieldPoliceCrimes from './fields/SelectFieldPoliceCrimes';
import InfoBox from '@/components/InfoBox';

export default {
  components: {
    TextField,
    SelectField,
    SelectFieldPoliceCrimes,
    InfoBox
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    label_boj: String,
    label_police: String,
    infoBox_boj: String,
    infoBox_police_primary: String,
    infoBox_police_secondary: String,
    crime_type: String,
    crime: Object,
    ticket: Object,
    options: Object,
  },
  data() {
    return {
      body: '',
      subject: '',
      getSortByOrder: true,
      getSortBy: '_score',
    };
  },
  computed: {
    policeCrimesById: function () {
      if (this.crime.crime_id !== 111) {
        return this.$store.getters.getPoliceCrimeByCrimeId(this.crime.crime.id);
      } else {
        return this.options.police_crimes
      }
    },
  },

  methods: {
    updatePoliceCrimeFields() {
      let currentCrime = this.crime.crime;

      this.updateExternalField('police_crime_id', null);
      this.updateExternalField('police_crime_type', null);
      this.crime.police_crime_id = null;
      this.crime.police_crime_type = null;
      this.crime.police_crime = null;

      if (this.crime.crime == null) {
          this.updateExternalField('crime_id', 111);
          this.updateTicketExternalField('crime_id', 111)

          this.crime.crime = this.options.crime.find(val => val.id === 111);
      } else {
        this.updateTicketExternalField('crime_id', this.crime.crime.id)
      }

      if (this.crime_type == 'primary') {
        this.$emit('fieldSavedWithData', {key: 'crime.crime', value: currentCrime});
      } else if (this.crime_type == 'secondary') {
        this.$emit('fieldSavedWithData', {key: 'secondary_crimes', value: currentCrime});
      }

    },

    setPoliceCrimeCode(police_crime_id) {
      var lookup = this.$store.getters.getPoliceCrimeById(police_crime_id);

      this.crime.police_crime_type = lookup[0].police_crime_code;
      this.crime.police_crime_id = police_crime_id;
      this.crime.crime = this.options.crime.find((item) => item.id == lookup[0].crime_id);
      this.crime.crime_id = lookup[0].crime_id;

      this.updateExternalField('police_crime_type', lookup[0].police_crime_code);
      this.updateExternalField('police_crime_id', police_crime_id);
      this.updateTicketExternalField('crime_id', lookup[0].crime_id)
      this.updateExternalField('crime_id', lookup[0].crime_id);

    },
    searchPoliceCrimeCode(police_crime_code) {
      this.crime.police_crime_type = police_crime_code;
      var lookup = this.$store.getters.getPoliceCrimeByCode(this.crime.police_crime_type);

      if (lookup.length === 0) {
        this.$notify({
          group: 'foo',
          text: this.$t('labels.crime-type-lookup-failed'),
          type: 'warning',
          duration: 3000,
        });
      } else {
        this.$notify({
          group: 'foo',
          text: this.$t('labels.crime-type-lookup-success'),
          type: 'success',
          duration: 3000,
        });

        this.updateExternalField('police_crime_id', lookup[0].id);
        this.updateExternalField('crime_id', lookup[0].crime_id);
        this.updateTicketExternalField('crime_id', lookup[0].crime_id)
        this.updateExternalField('police_crime_type', lookup[0].police_crime_code);
        this.crime.police_crime_id = lookup[0].id;
        this.crime.police_crime_type = lookup[0].police_crime_code;
        this.crime.police_crime = lookup[0];

        this.crime.crime = this.options.crime.find((item) => item.id == lookup[0].crime_id);

        this.$emit('updateTicket');
      }
    },
    updateExternalField(field, value) {
      BojAPI.updateCrimeField(this.crime, field, value)
          .then(() => {
          })
          .catch((error) => {
          });
    },
    updateTicketExternalField(field, value) {
      BojAPI.updateTicketField(this.ticket, field, value)
        .then(() => {
        })
        .catch((error) => {
        });
    },
    setNewPoliceCrime() {
      this.crime.police_crime_id = this.crime.police_crime_type.id;
      //this.updateExternalField('police_crime_type', this.crime.police_crime_type.id);
    },
    handleClick() {
      this.$confirm({
        message: this.$t('labels.delete-crime-confirmation'),
        button: {
          no: 'Nej',
          yes: 'Ja',
        },
        callback: (confirm) => {
          if (confirm) {
            BojAPI.deleteCrime(this.crime.id)
                .then(() => {

                  this.crime.crime = null
                  this.$emit('fieldSavedWithData', {key: 'secondary_crimes', value: null});

                  this.$notify({
                    group: 'foo',
                    text: this.$t('labels.crime-deleted'),
                    type: 'success',
                    duration: 3000,
                  });
                })
                .catch((error) => {
                  this.$notify({
                    group: 'foo',
                    text: this.$t('labels.no-permission'),
                    type: 'warning',
                    duration: 3000,
                  });
                });
          }
        },
      });
    },
  },
};
</script>
