<template>
  <div class="form-group row">
    <label :for="db_field + '_field'" :class="{ 'col-sm-4': !fullWidth, 'col-sm-12': fullWidth }" class="col-form-label"
           v-if="label.length !== 0">{{
        label
      }}</label>
    <div :class="label.length !== 0 && !fullWidth ? 'col-sm-8' : 'col-12'">
      <v-select
          v-if="editable"
          :label="optionLabel"
          :multiple="multiple"
          :id="db_field + '_field'"
          @input="updateField(db_field, $event)"
          :options="options"
          v-model="model[field]"
          :clearable="clearable"
      ><span slot="no-options">Inga värden</span></v-select
      >

      <div class="pt-2" v-if="!editable && !multiple && !isModelFieldEmpty">{{ model[field][optionLabel] }}</div>
      <div class="pt-2" v-if="!editable && multiple && !isModelFieldEmpty">
        <span v-for="item in model[field]" :key="item[optionLabel]">{{ item[optionLabel] }}, </span>
      </div>
    </div>
  </div>
</template>

<script>
import BojAPI from '@/api/boj';
import _ from 'lodash'
import { i18n } from '@/plugins/i18n'

export default {
  name: 'loader',
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    optionLabel: {
      type: String,
      default: 'label',
    },

    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    api: String,
    options: Array,
    model: Object,
    field: String,
    db_field: String,
    type: String,
    label: String,
  },
  computed: {
    isModelFieldEmpty() {
      if (_.isObject(this.model[this.field])) {
        if (_.isArray(this.model[this.field]) && !this.model[this.field].length) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    updateField(field, event) {
      let value = '';
      let fieldname = field;

      if (this.model[this.field] === null) {
        value = null;
      } else {
        if (this.multiple) {
          value = this.model[this.field];
        } else {
          value = this.model[this.field].id;
        }
      }

      BojAPI[this.api](this.model, field, value)
          .then((response) => {
            this.$emit('fieldSaved', value);
            this.$emit('fieldSavedWithData', field);

            this.$notify({
              group: 'foo',
              text: this.$t('labels.changes-saved'),
              type: 'success',
              duration: 3000,
            });
            this.reRender = true;
          })
          .catch((error) => {
            this.$notify({
              group: 'foo',
              text: this.$t('labels.no-permission'),
              type: 'warning',
              duration: 3000,
            });
          });
    },
  },
};
</script>
